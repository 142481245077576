
    @use "sass:color";
    @use "styles/palette" as palette;
    @use "styles/variables" as variables;
    
$a4-calendar-width: 1080px;

html {
  scroll-behavior: smooth;
  height: 100%;
}

body {
  height: 100%;
}

div[id='__next'] {
  height: 100%;
}

.et-legacy-iframe {
  height: calc(100% - 5px);
  width: 100%;
  border: none;
}

.intercom-lightweight-app {
  z-index: variables.$intercom-z-index !important;
}

.intercom-app > * {
  z-index: variables.$intercom-z-index !important;
}

.print-calendar {
  .fc-view-harness {
    width: $a4-calendar-width !important;
  }

  .fc-scrollgrid {
    width: $a4-calendar-width !important;
  }

  .fc-day-today {
    background-color: transparent !important;
  }

  .hide-from-print {
    display: none;
  }
}

// Adding canny css for the badge when the navigation is in mini mode
[data-nav-mini='true'] {
  .Canny_Badge {
    width: 36px !important;
    height: 12px !important;
    border-radius: 50% !important;
    top: 5px !important;
    right: 65px !important;
  }
}
